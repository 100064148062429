import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheterTirageAvecPrix from "../../../../composants/boutique/BoutonAcheterTirageAvecPrix"
import Image from "../../../../composants/image/Image"
import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../../composants/image/Panellum"

import {
  obtenirPage,
  obtenirImage,
  obtenirImages,
} from "../../../../js/client-es/utils"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "voieLacteeIntegralite360EN" } }
    ) {
      ...FragmentPageYaml
    }
    imageAVendre: allImagesCompilationYaml(
      filter: { code: { in: ["voieLacteeIntegralite"] } }
    ) {
      nodes {
        ...FragmentDonneesImages
      }
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["boutiqueVoieLacteeCaisseAmericaine"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
  }
`

export default function VoieLacteeIntegralite360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")
  const images = obtenirImages(
    resultatsRequete,
    "imageAVendre",
    "imagesPaysageMoyen"
  )

  const composantImageInteractive = (
    <Pannellum
      urlImageSource="/ressources/360/voieLacteeIntegralite.jpg"
      altitudeParDefaut={0}
      angleDeVue={80}
      angleDeVueVertical={50}
    />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          This image is the result of a collaborative work of one year and a
          half made with my wife. One year and a half of shooting from Chile to
          Brittany in France, taking pictures of each part of the Milky Way so
          that it was at the zenith.
        </p>
        <p>
          This image reveals what you would see if you put yourself in the shoes
          of our Sun. Yes, imagine, there you are. You look all around from you.
          It is pitch black. What do you see ? This is the Milky Way, our galaxy
          of 100 billion Suns. You see it by the slice, because our Solar system
          is in the galactic disk.
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          Canon EOS 6D Astrodon modded, Sigma 24mm Art F1.4 set to F2. Mosaic of
          12 images. Total exposure time: 10h40.
        </div>
        <div id="galerie-image-interactive-conteneur-achat-tirage-avec-prix">
          {" "}
          <BoutonAcheterTirageAvecPrix
            image={obtenirImage(images, "voieLacteeIntegralite")}
            langue={page.langue}
          />
        </div>
        <Image
          image={obtenirImage(images, "boutiqueVoieLacteeCaisseAmericaine")}
          langue={page.langue}
        />
      </section>
    </LayoutGalerieImageInteractive>
  )
}
